import React from 'react';
import Helmet from 'react-helmet';
import Navigation from './navigation';
import Footer from './footer';

import Favicon from '../../static/img/favicon.png';
import '../../static/css/main.css';

export default function Layout({ children }) {
  const description = 'Easton Place Medical Centre is now officially open and accepting new patients. Encompassing family medicine, walk-ins, women\'s health, dental care, pharmacy, and more.';
  return (
    <>
      <Helmet
        titleTemplate="%s | Easton Place Medical Centre"
      >
        <title>Welcome</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={description} />
        <meta property="og:title" content="Welcome to Easton Place" />
        <meta property="og:site_name" content="Easton Place Medical Centre" />
        <meta property="og:url" content="https://eastonplace.ca" />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="place" />
        <meta property="og:image" content="https://develop.eastonplace.ca/img/providers/easton-place.jpg" />
        <link rel="icon" href={Favicon} type="image/x-icon" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.2.1/css/bootstrap.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0-2/css/all.min.css"
        />
        
      </Helmet>
      <Navigation />
      {children}
      <Footer />
    </>
  );
}
