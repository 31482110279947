import React from 'react';
import c from 'classnames';

import BannerImage from '../../static/img/banner.jpg';
import BannerMobileImage from '../../static/img/banner-mobile.jpg';
import LogoImage from '../../static/img/logo.svg';

import styles from './splash.module.css';

export default function Splash() {
  return (
    <section className={c(styles.splash)}>
      <img src={BannerImage} className="d-none d-md-block" style={{ width: '100%' }} alt="Easton Place Medical Centre" />
      <img src={BannerMobileImage} className="d-block d-md-none" style={{ width: '100%' }} alt="Easton Place Medical Centre" />
      <div className={c(styles.splashContent, 'py-4', 'py-md-5')}>
        <div className="container">
          <div className="row">
            <div className="d-none d-md-block col-2">
              <img src={LogoImage} className="px-5 px-md-0" style={{ width: '100%' }} alt="Easton Place logo" />
            </div>
            <div className="col-12 col-md-10 pl-md-5">
              <p className="text-medium mt-0">
                Welcome to Easton Place!
              </p>
              <p>
                We are pleased to announce that the Easton Place Medical Centre is now officially open and accepting new patients. Encompassing family medicine, walk-ins, women's health, dental care, pharmacy, and audiology, our clinic provides a wide array of healthcare services in a single convenient location.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}